import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const About = () => (
  <Layout>
    <SEO title="About Prolacs"
      description="Prolacs is a brand of Konzek Technology Inc. With its 14 years of knowledge and experience" />
    <Container>
      <Row className='prolacs-about-us-row' >
        <Col sm>
          <div className='prolacs-divider-title'>
            <br></br>
            <br></br>
            <h1>About Prolacs</h1>
          </div>
          <p style={{ height: '100vh' }}>Prolacs is a brand of  <a href="https://www.konzek.com"><b>Konzek Technology Inc. </b></a>
            With its 14 years of knowledge and experience, Konzek Technology develops solutions for Industry 4.0 compliant in Manufacturing Execution (MES), Production Efficiency (OEE), Environmental Monitoring, Energy Management, System Automation and Process Control areas, using current software and hardware technologies.
          <br></br>
            <br></br>
            Under the Prolacs brand, it develops cloud based and artificial intelligence supported laundry monitoring / management systems and Laundromat control systems.
            For 14 years we have been manufacturing control devices and sensors for washing and drying machines.
          The controllers and sensors that we produce are preferred by leading brands in 70+ countries of the world. So far we have produced 30,000+ controllers and 45,000+ sensors.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default About
